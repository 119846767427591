import React from 'react';
import { ThemeProvider } from 'react-bootstrap';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Router from './routes/Router';

function App() {
  return (

    <ThemeProvider
      breakpoints={['xxxl', 'xxl', 'xl', 'lg', 'md', 'sm', 'xs', 'xxs']}
      minBreakpoint="xxs">
        <Router />
    </ThemeProvider>
  );
}

export default App;
