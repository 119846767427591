import React, { useEffect, useState } from 'react'
import { Button, Container } from 'react-bootstrap'
import NavBar from '../pages/LandingPage/sections/NavBar'
import { useMediaQuery } from 'react-responsive'
import { t } from 'i18next'
import { redirect, useNavigate } from 'react-router-dom'

type Props = {
    type: string
}

const Result = (props: Props) => {

    const navigate = useNavigate()

    const isDesktopOrLaptop = useMediaQuery({query: '(min-width: 1224px)'})
    const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
    const isTablet = useMediaQuery({ query: '(max-width: 1224px)' })
    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
    const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })
    const isMobile = useMediaQuery({ query: '(max-width: 500px)' })

    const pendingColor = "#F2B950"
    const errorColor = "#DB4B62"
    const successColor = "#31D67A"


  return (
    <Container fluid style={{backgroundColor: "#BBD3F2", height: "100vh"}}>  
        <NavBar></NavBar>
        <Container style={{width: (isBigScreen || isDesktopOrLaptop ) ? "40%" : "95%", paddingTop: "0px", paddingBottom: "0px", fontFamily: "Montserrat"}}>
            <Container style={{display: "flex", flexDirection: "column", alignItems: "center", backgroundColor: (props.type === "success") ? successColor : (props.type === "error") ? errorColor : pendingColor, borderRadius: "60px"}}>
                <h1 style={{textAlign: "center", color: "white", fontWeight: "700", paddingBottom: "30px", paddingTop: "60px"}}>{ (props.type === "success") ? t("payment_success.title") : (props.type === "error") ? t("payment_error.title") : t("payment_pending.title")}</h1>
                <p style={{textAlign: "center", color: "white", fontWeight: "700", paddingBottom: "60px"}}>{(props.type === "success") ? t("payment_success.description") : (props.type === "error") ? t("payment_error.description") : t("payment_pending.description")}</p>
                <Button id='button-primary' onClick={() => navigate("/")} style={{backgroundColor: "white", color: (props.type === "success") ? successColor : (props.type === "error") ? errorColor : pendingColor, width: "auto", marginBottom: "60px"}}>{t("next_btn")}</Button>
            </Container>
        </Container>
    </Container>
  )
}

export default Result