import { t } from 'i18next'
import React from 'react'
import { Container, Nav, Navbar } from 'react-bootstrap'
import { useMediaQuery } from 'react-responsive'
import { useNavigate } from 'react-router-dom'

type Props = {}

const NavBar = (props: Props) => {

  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1224px)'
  })
  const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })

  const navigate = useNavigate()

  return (
    <Navbar collapseOnSelect expand="lg" style={{fontFamily: "Montserrat", fontWeight: "400", paddingTop: "50px", paddingBottom: "50px", paddingLeft: "20px", paddingRight: "20px"}}>
      <Container className='d-flex justify-content-justify-content-center'>
        <Navbar.Brand href="/"><img src='./logo-blue.svg' alt='logo' width={(isBigScreen || isDesktopOrLaptop) ? "365px" :  "194px"} /></Navbar.Brand>
        <Navbar.Toggle id='menu-toggler' aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse className='me-auto justify-content-end ' id="responsive-navbar-nav">
          <Nav style={(isTabletOrMobile || isPortrait) ? {display: "flex", alignItems: "center", paddingTop: "25px", color: "#135EF2"} : {}} >
            <Nav.Link className='nav-link' style={{color: "#135EF2"}} onClick={() => window.location.assign('https://informes.digital/?utm_source=pack_informes_digital&utm_medium=navbar&utm_campaign=pack')}>{t('landing_page.navbar.1')}</Nav.Link>
            <Nav.Link className='nav-link' style={{color: "#135EF2"}} onClick={() => window.location.assign('https://informes.digital/radicacion-de-patente/?utm_source=pack_informes_digital&utm_medium=navbar&utm_campaign=pack')} >{t('landing_page.navbar.2')}</Nav.Link>
            <Nav.Link className='nav-link' style={{color: "#135EF2"}} onClick={() => window.location.assign('https://informes.digital/informe-de-multas/?utm_source=pack_informes_digital&utm_medium=navbar&utm_campaign=pack')} >{t('landing_page.navbar.3')}</Nav.Link>
            <Nav.Link className='nav-link' style={{color: "#135EF2"}} onClick={() => window.location.assign('https://informes.digital/informe-nominal/?utm_source=pack_informes_digital&utm_medium=navbar&utm_campaign=pack')} >{t('landing_page.navbar.4')}</Nav.Link>
            <Nav.Link className='nav-link' style={(isTabletOrMobile || isPortrait) ? {color: "#135EF2"} : {border: "1px solid", borderRadius: "8px", marginLeft: "10px", color: "#135EF2", borderColor: "#135EF2"}}  onClick={() => navigate("/contact")}>{t('landing_page.navbar.5')}</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

export default NavBar