import { t } from 'i18next'
import React from 'react'
import { Card, Carousel, Col, Container, Row } from 'react-bootstrap'
import { useMediaQuery } from 'react-responsive'

type Props = {}

const Testimonials = (props: Props) => {

    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
      })
      const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
      const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
      const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
      const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })

  return (
    <>
        <Container fluid style={{fontFamily: "Montserrat", paddingTop: `${(isBigScreen || isDesktopOrLaptop) ? '100px' : '70px' }`, paddingBottom: `${(isBigScreen || isDesktopOrLaptop) ? '100px' : '30px' }`, backgroundColor: "#135EF2"}}>
            <h3 style={{textAlign: "center", fontWeight: "800", color: "white", paddingBottom: "100px"}}>{t('landing_page.testimonials.title')}</h3>
            <Container>
                <Carousel indicators={false}>
                    <Carousel.Item>
                        <Card className='d-flex align-items-center' style={{background: "none", border: "none"}}>
                            <Card.Img src='./avatar-2.png' style={{border: "2px solid", borderColor: "transparent", borderRadius: "70px", width: "80px", marginBottom: "-35px", zIndex: "1000"}}></Card.Img>
                            <div style={{backgroundColor: "#BBD3F2", borderRadius: "60px", padding: "60px"}}>
                                <Card.Text style={{color: "#135EF2", fontWeight: "600", fontSize: "16px", textAlign: "center", maxWidth: "240px"}}>{t('landing_page.testimonials.cards.2.text')}</Card.Text>
                                <Card.Text style={{color: "#135EF2", fontWeight: "900", textTransform: "uppercase", textAlign: "center"}}>{t('landing_page.testimonials.cards.2.name')}</Card.Text>
                            </div>
                        </Card>
                    </Carousel.Item>
                    <Carousel.Item>
                        <Card className='d-flex align-items-center' style={{background: "none", border: "none"}}>
                            <Card.Img src='./avatar-1.png' style={{border: "2px solid", borderColor: "transparent", borderRadius: "70px", width: "80px", marginBottom: "-35px", zIndex: "1000"}}></Card.Img>
                            <div style={{backgroundColor: "#BBD3F2", borderRadius: "60px", padding: "60px"}}>
                                <Card.Text style={{color: "#135EF2", fontWeight: "600", fontSize: "16px", textAlign: "center", maxWidth: "240px"}}>{t('landing_page.testimonials.cards.1.text')}</Card.Text>
                                <Card.Text style={{color: "#135EF2", fontWeight: "900", textTransform: "uppercase", textAlign: "center"}}>{t('landing_page.testimonials.cards.1.name')}</Card.Text>
                            </div>
                        </Card>
                    </Carousel.Item>
                    <Carousel.Item>
                        <Card className='d-flex align-items-center' style={{background: "none", border: "none"}}>
                            <Card.Img src='./avatar-3.png' style={{border: "2px solid", borderColor: "transparent", borderRadius: "70px", width: "80px", marginBottom: "-35px", zIndex: "1000"}}></Card.Img>
                            <div style={{backgroundColor: "#BBD3F2", borderRadius: "60px", padding: "60px"}}>
                                <Card.Text style={{color: "#135EF2", fontWeight: "600", fontSize: "16px", textAlign: "center", maxWidth: "240px"}}>{t('landing_page.testimonials.cards.3.text')}</Card.Text>
                                <Card.Text style={{color: "#135EF2", fontWeight: "900", textTransform: "uppercase", textAlign: "center"}}>{t('landing_page.testimonials.cards.3.name')}</Card.Text>
                            </div>
                        </Card>
                    </Carousel.Item>
                </Carousel>
            </Container>
        </Container>
    </>
  )
}

export default Testimonials