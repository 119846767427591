import { t } from 'i18next'
import React from 'react'
import { Button, Card, Col, Container, Row } from 'react-bootstrap'
import { useMediaQuery } from 'react-responsive'

type Props = {}

const Data = (props: Props) => {
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
      })
    const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
    const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })

    const cardTitleStyle: any = {color: "#135EF2", fontWeight: "800", fontSize: "3.5rem"}
    const cardDescriptionStyle: any = {color: "#135EF2", fontWeight: "400", fontSize: "1.4rem", maxWidth: (isTabletOrMobile) ? "100%" : "80%", textAlign: "center"}
    const cardImageStyle: any = {padding: "30px", height: "240px", width: "auto"}
    const cardStyle: any = {display: "flex", flexDirection: "column", alignItems: "center", gap: "10px", backgroundColor: "transparent", border: "none"}


  return (
    <Container fluid style={{fontFamily: "Montserrat", paddingTop: "100px", paddingBottom: "100px", backgroundColor: "#BBD3F2"}}>
        <h3 style={{textAlign: "center", fontWeight: "800", color: "#135EF2", paddingBottom: "100px"}}>{t("landing_page.data.title")}</h3>
        <Container style={{backgroundColor: "white", borderRadius: "60px", padding: (isTabletOrMobile) ? "30px" : "120px"}}>
            <Row>
                <Col>
                    <Card style={cardStyle}>
                        <Card.Img style={cardImageStyle} src='./tramites-resueltos.svg' />
                        <Card.Title style={cardTitleStyle}>
                            {t("landing_page.data.1.title")}
                        </Card.Title>
                        <Card.Text style={cardDescriptionStyle}>
                            {t("landing_page.data.1.description")}
                        </Card.Text>
                    </Card>
                </Col>
                <Col>
                    <Card style={cardStyle}>
                        <Card.Img style={cardImageStyle} src='./profesionales-en-nuestra-red.svg' />
                        <Card.Title style={cardTitleStyle}>
                            {t("landing_page.data.2.title")}
                        </Card.Title>
                        <Card.Text style={cardDescriptionStyle}>
                            {t("landing_page.data.2.description")}
                        </Card.Text>
                    </Card>
                </Col>
                <Col>
                    <Card style={cardStyle}>
                        <Card.Img style={cardImageStyle} src='./gestiones-en-nuestro-catalogo.svg' />
                        <Card.Title style={cardTitleStyle}>
                            {t("landing_page.data.3.title")}
                        </Card.Title>
                        <Card.Text style={cardDescriptionStyle}>
                            {t("landing_page.data.3.description")}
                        </Card.Text>
                    </Card>
                </Col>
                <Col>
                    <Card style={cardStyle}>
                        <Card.Img style={cardImageStyle} src='./valoraciones-positivas.svg' />
                        <Card.Title style={cardTitleStyle}>
                            {t("landing_page.data.4.title")}
                        </Card.Title>
                        <Card.Text style={cardDescriptionStyle}>
                            {t("landing_page.data.4.description")}
                        </Card.Text>
                    </Card>
                </Col>


            </Row>

        </Container>
    </Container>
  )
}

export default Data