import axios from 'axios'
import React, { useState } from 'react'
import { Button, Container, FormControl, FormGroup, FormLabel, FormSelect } from 'react-bootstrap'
import { useMediaQuery } from 'react-responsive'
import NavBar from './sections/NavBar'
import { redirect } from "react-router-dom";
import FormularioInforme from '../../components/FormularioInforme'
import FormularioContacto from '../../components/FormularioContacto'

type Props = {
  isContactForm: boolean
}

const Form = (props: Props) => {

    const isDesktopOrLaptop = useMediaQuery({query: '(min-width: 1224px)'})
    const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
    const isTablet = useMediaQuery({ query: '(max-width: 1224px)' })
    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
    const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })
    const isMobile = useMediaQuery({ query: '(max-width: 500px)' })
    
  return (
    <Container fluid style={{backgroundColor: "#BBD3F2", height: "120vh"}}>  
        <NavBar></NavBar>
        <Container style={{width: (isBigScreen || isDesktopOrLaptop ) ? "40%" : "95%", paddingTop: "0px", paddingBottom: "0px", fontFamily: "Montserrat"}}>
            <h1 style={{textAlign: "center", color: "#135EF2", fontWeight: "700", paddingBottom: "30px"}}>{(props.isContactForm === true) ? 'Contacto' : 'Solicitar informe'}</h1>
            {(props.isContactForm === true) ? <FormularioContacto /> : <FormularioInforme isInformeNominal={false} color={'#64ACCE'} />}
        </Container>
    </Container>
  )
}

export default Form