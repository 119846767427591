import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Form from '../pages/LandingPage/Form'
import LandingPage from '../pages/LandingPage/LandingPage'
import InformeIndividual from '../pages/LandingPage/InformeIndividual'
import Result from '../components/Result'

export default function Router() {
  return (
    <Routes>
        <Route path='/' element={<LandingPage />}></Route>
        <Route path='/form' element={<Form isContactForm={false} />}></Route>
        <Route path='/informe-de-dominio' element={<InformeIndividual />}></Route>
        <Route path='/radicacion-de-patente' element={<InformeIndividual />}></Route>
        <Route path='/informe-de-multas' element={<InformeIndividual />}></Route>
        <Route path='/informe-nominal' element={<InformeIndividual />}></Route>
        <Route path='/contact' element={<Form isContactForm={true} />}></Route>
        <Route path='/success' element={<Result type="success"></Result>}></Route>
        <Route path='/pending' element={<Result type="pending"></Result>}></Route>
        <Route path='/error' element={<Result type="error"></Result>}></Route>
    </Routes>
  )
}
