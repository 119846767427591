import axios from 'axios'
import { t } from 'i18next'
import React, { useEffect, useState } from 'react'
import { Alert, Button, Form, FormCheck, FormControl, FormGroup, FormLabel, FormSelect } from 'react-bootstrap'
import { redirect, useLocation } from 'react-router-dom'

type Props = {
    color: any
    isInformeNominal: boolean
}

const FormularioInforme = (props: Props) => {

    const baseUrl = process.env.REACT_APP_API_URL

    const config = {
        headers:{
        'content-type': 'application/json;charset=UTF-8',
        'accept': 'application/json, text/plain, */*'
        }
    }

    const location = useLocation()

    const [validated, setValidated] = useState(false);
    const [showAlert, setShowAlert] = useState(false)

    useEffect(() => {
        const form: any = document.getElementById("form-id")
        form.reset()
    }, [location])

    const [email, setEmail] = useState("")
    const [dominio, setDominio] = useState("")
    const [cuit, setCuit] = useState("")
    const [cuit_request, setCuit_request] = useState("")
    const [fullname, setFullname] = useState("")
    const [fullname_request, setFullname_request] = useState("")
    const [model, setModel] = useState("")
    const [type_id, setType_id] = useState(10)
    const [province_id, setProvince_id] = useState<number>(14)

    const provinces = [{"id":1,"name":"Buenos Aires","created_at":null,"updated_at":null},{"id":2,"name":"CABA","created_at":null,"updated_at":null},{"id":3,"name":"Catamarca","created_at":null,"updated_at":null},{"id":4,"name":"Chaco","created_at":null,"updated_at":null},{"id":5,"name":"Chubut","created_at":null,"updated_at":null},{"id":6,"name":"C\u00f3rdoba","created_at":null,"updated_at":null},{"id":7,"name":"Corrientes","created_at":null,"updated_at":null},{"id":8,"name":"Entre R\u00edos","created_at":null,"updated_at":null},{"id":9,"name":"Formosa","created_at":null,"updated_at":null},{"id":10,"name":"Jujuy","created_at":null,"updated_at":null},{"id":11,"name":"La Pampa","created_at":null,"updated_at":null},{"id":12,"name":"La Rioja","created_at":null,"updated_at":null},{"id":13,"name":"Mendoza","created_at":null,"updated_at":null},{"id":14,"name":"Misi\u00f3nes","created_at":null,"updated_at":null},{"id":15,"name":"Neuqu\u00e9n","created_at":null,"updated_at":null},{"id":16,"name":"R\u00edo Negro","created_at":null,"updated_at":null},{"id":17,"name":"Salta","created_at":null,"updated_at":null},{"id":18,"name":"San Juan","created_at":null,"updated_at":null},{"id":19,"name":"San Luis","created_at":null,"updated_at":null},{"id":20,"name":"Santa Cruz","created_at":null,"updated_at":null},{"id":21,"name":"Santa Fe","created_at":null,"updated_at":null},{"id":22,"name":"Santiago del Estero","created_at":null,"updated_at":null},{"id":23,"name":"Tierra del Fuego","created_at":null,"updated_at":null},{"id":24,"name":"Tucum\u00e1n","created_at":null,"updated_at":null}]

    const emailInputHandler = (event: any) => {
        event.preventDefault()
        setEmail(event.target.value)
    }

    const dominioInputHandler = (event: any) => {
        event.preventDefault()
        setDominio((event.target.value).toUpperCase())
    }

    const cuitToConsultInputHandler = (event: any) => {
        event.preventDefault()
        setCuit_request(event.target.value)
    }

    const cuitInputHandler = (event: any) => {
        event.preventDefault()
        setCuit(event.target.value)
    }

    const provinceSelectHandler = (event: any) => {
        setProvince_id(event.target.value)
    }

    const data = {"email": email, "dominio": dominio, "cuit": cuit, "cuit_request": cuit_request, "fullname": fullname, "fullname_request": fullname_request, "model": model, "type_id": type_id, "province_id": province_id}

    const handleSubmit = async(event: any) => {
        const form = event.currentTarget;

        if (form.checkValidity() === false || !isCuitValid || !validationDominio) {
          event.preventDefault();
          event.stopPropagation();
        }
        else {
        event.preventDefault();
        setValidated(true);
        await axios.post(`${baseUrl}save-informe-pendding`, data, config).then(async(res) => {
            await axios.get(`${baseUrl}payment/${res.data.id}`).then((res) => {window.location.replace(`${res.data.init_point}`)}).catch(e => console.log(e))
        }).catch(e => {console.log(e); setShowAlert(true)})}
    }

    const primaryColor = "#135EF2"

    const [validationDominio, setValidationDominio] = useState<boolean>()
    
    const motorcycleNumberPlates = [
        {pattern: /^\d{3}[A-Z]{3}$/, needsExtraData: false, maxLength: 6},
        {pattern: /^[A-Z]{1}\d{3}[A-Z]{3}$/, needsExtraData: true, maxLength: 7},
      ];
    const numberPlate = [
          ...(motorcycleNumberPlates),
        {pattern: /^[A-Z]{3}\d{3}$/, needsExtraData: false, maxLength: 6},
        {pattern: /^[A-Z]{2}\d{3}[A-Z]{2}$/, needsExtraData: true, maxLength: 7},
      ];

    const isValidPatent = (e: any) => {
        for (const element of numberPlate){
            const isValid = element.pattern.test((e.target.value).toUpperCase())
            if (isValid) {
                setValidationDominio(true);
                break
            } 
            else {
                setValidationDominio(false);
            }
        }
    }

    const [isCuitValid, setIsCuitValid] = useState<boolean>()

    function cuitValidation(cuil: string) {
        let mult = [5, 4, 3, 2, 7, 6, 5, 4, 3, 2];
        let total = 0;
        let cuit = Array();
        cuil = cuil ? cuil.toString().replace(/-/g, "") : "";
        for (let x = 0; x < 11; x++) {
            if (cuil.substr(x, 1) !== "") {
                cuit[x] = cuil.substr(x, 1);
            }
        }
        if (cuit.length === 0) {setIsCuitValid(true)} 
        if (cuit.length === 11) {
            let prim = cuit[0] + cuit[1];
            if (prim == 27 || prim == 20 || prim == 23 || prim == 30 || prim == 33 || prim == 34 || prim == 24) {
                for (let i = 0; i < mult.length; i++) {
                    total += parseInt(cuit[i]) * mult[i];
                }
                let mod = total % 11;
                let digito = (mod == 0) ? 0 : (mod == 1) ? 9 : 11 - mod;
                if (digito === parseInt(cuit[10])) {
                    setIsCuitValid(true)
                    return true;
                } else {
                    setIsCuitValid(false)
                    return false;
                }
            } else {
                setIsCuitValid(false)
                return false;
            }
        } else {
            setIsCuitValid(false)
            return false;
        }
    }
      
    

  return (
    <>
        <Alert className='d-flex flex-row justify-content-between align-items-center' show={showAlert} variant='danger'>
            {t("danger_informe_not_sent")}
            <Button onClick={() => setShowAlert(false)} variant="outline-danger">
                {'Cerrar'}
            </Button>
        </Alert>
        <Form 
        style={{paddingBottom: "50px"}}
        id='form-id'
        validated={validated} 
        onSubmit={handleSubmit}>
            <FormGroup style={{paddingBottom: "30px"}}  controlId="validationCustom01" >
                <FormLabel style={{color: primaryColor}}>{'Correo Electrónico'}</FormLabel>
                <FormControl style={{height: "60px"}} type='email' onChange={emailInputHandler} required ></FormControl>
                <FormControl.Feedback type='invalid'>{'Por favor escribe tu correo electrónico'}</FormControl.Feedback>
            </FormGroup>
            <FormGroup style={{paddingBottom: "30px"}} >
                <FormLabel style={{color: primaryColor}}>{'Dominio'}</FormLabel>
                <FormControl value={dominio} style={{height: "60px"}} type='text' onChange={(e: any) => {dominioInputHandler(e); isValidPatent(e)}} isInvalid={validationDominio !== undefined && !validationDominio} isValid={validationDominio} ></FormControl>
            </FormGroup>

            <FormGroup hidden={!props.isInformeNominal} style={{paddingBottom: "30px"}}>
                <FormLabel style={{color: primaryColor}}>{'CUIT/CUIL de la persona fisica o juridica a consultar'}</FormLabel>
                <FormControl style={{height: "60px"}} type='text' onChange={cuitToConsultInputHandler}></FormControl>
            </FormGroup>

            <FormGroup style={{paddingBottom: "30px"}}>
                <FormLabel style={{color: primaryColor}}>{'CUIT/CUIL del solicitante'}</FormLabel>
                <FormControl required style={{height: "60px"}} type='text' onChange={(e: any) => {cuitInputHandler(e); cuitValidation(e.target.value)}} isInvalid={isCuitValid !== undefined && !isCuitValid} isValid={isCuitValid}></FormControl>
            </FormGroup>
            <FormGroup style={{paddingBottom: "30px"}}>
                <FormLabel style={{color: primaryColor}}>{'Seleccioná la provincia (o jurisdicción) a la que pertenecés'}</FormLabel>
                <FormSelect style={{height: "60px"}} onChange={provinceSelectHandler}>
                    <option disabled>{'Selecciona la provincia'}</option>
                    {provinces.map((element: any) => <option placeholder='Selecciona la provincia' value={element.id}>{element.name}</option>)}
                </FormSelect>
            </FormGroup>
            <FormGroup>
                <FormCheck
                required
                type='checkbox'
                id='tyc-checkbox'
                label={<p style={{color: primaryColor}}>He Leído y Acepto las <a style={{color: primaryColor}} href='https://informes.digital/politicas-de-privacidad' rel="noopener noreferrer" target='_blank'>Política de privacidad</a> y los <a style={{color: primaryColor}} href='https://informes.digital/terminos-y-condiciones-generales-de-contratacion' rel="noopener noreferrer" target='_blank'>Términos y Condiciones</a> de contratación.</p>}>
                </FormCheck>
            </FormGroup>
            <Button type='submit' style={{marginTop: "20px", backgroundColor: primaryColor}} id='button-primary'>{'Solicitar Informe'}</Button>
            <p style={{color: primaryColor, fontSize: "13px", fontWeight: "400", paddingTop: "5px"}}>{"El costo del servicio es de $5599"}</p>
        </Form>
    </>
  )
}

export default FormularioInforme