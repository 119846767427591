import React, { useContext, useEffect, useState } from 'react'
import { Accordion, AccordionCollapse, AccordionContext, Button, Card, Col, Container, Row, useAccordionButton } from 'react-bootstrap'
import { useMediaQuery } from 'react-responsive'
import NavBar from './sections/NavBar'
import { useLocation, useNavigate } from 'react-router-dom'
import { t } from 'i18next'
import CardHeader from 'react-bootstrap/esm/CardHeader'
import FormularioInforme from '../../components/FormularioInforme'
import Footer from './sections/Footer'


function ContextAwareToggle({ children, eventKey, callback }: any) {
    const { activeEventKey }: any = useContext(AccordionContext);
  
    const decoratedOnClick = useAccordionButton(
      eventKey,
      () => callback && callback(eventKey),
    );
  
    const isCurrentEventKey = activeEventKey === eventKey;
  
    return (
      <button
        type="button"
        style={{ backgroundColor: "transparent", border: "none", color: "#E3E3E3", textAlign: "center", width: "100%", fontSize: "600", height: "60px" }}
        onClick={decoratedOnClick}
      >
        {children}
      </button>
    );
  }


type Props = {}

const InformeIndividual = (props: Props) => {

    const isDesktopOrLaptop = useMediaQuery({query: '(min-width: 1224px)'})
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
    const isTablet = useMediaQuery({ query: '(max-width: 1224px)' })
    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
    const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })
    const isMobile = useMediaQuery({ query: '(max-width: 500px)' })

    const location = useLocation()
    const navigate = useNavigate()

    const [bgColor, setBgColor] = useState("blue")
    const [bgColorCode, setBgColorCode] = useState("")
    const [title, setTitle] = useState("")
    const [subtitle, setSubtitle] = useState("")
    const [img, setImg] = useState("/dominio-mockup.png")
    const [description, setDescription] = useState("")
    const [faqs, setFaqs] = useState<any>([{id: "", question: "", answer: ""}])
    const [isInformeNominal, setIsInformeNominal] = useState(false)

    useEffect(() => {
      
        switch (location.pathname) {
            case "/informe-de-dominio": 
                setIsInformeNominal(false)
                setBgColor("green");
                setBgColorCode('#61C5AB');
                setTitle(t('informes_individuales.informe_dominio.title')||"");
                setSubtitle(t('informes_individuales.informe_dominio.subtitle')||"");
                setImg("/dominio-mockup.png");
                setDescription(t('informes_individuales.informe_dominio.description')||"");
                setFaqs([{id: "1", question: t("informes_individuales.informe_dominio.faqs.1.question"), answer: t("informes_individuales.informe_dominio.faqs.1.answer")},
                {id: "2", question: t("informes_individuales.informe_dominio.faqs.2.question"), answer: t("informes_individuales.informe_dominio.faqs.2.answer")},
                {id: "3", question: t("informes_individuales.informe_dominio.faqs.3.question"), answer: t("informes_individuales.informe_dominio.faqs.3.answer")},
                {id: "4", question: t("informes_individuales.informe_dominio.faqs.4.question"), answer: t("informes_individuales.informe_dominio.faqs.4.answer")}
            ]);
                break;
            case "/radicacion-de-patente": 
                setIsInformeNominal(false)
                setBgColor("purple"); 
                setBgColorCode('#6F65C8'); 
                setTitle(t('informes_individuales.informe_patente.title')||""); 
                setSubtitle(t('informes_individuales.informe_patente.subtitle')||"");
                //TODO: change the mockup here
                setImg("/dominio-mockup.png"); 
                setDescription(t('informes_individuales.informe_patente.description')||"");
                setFaqs([{id: "1", question: t("informes_individuales.informe_patente.faqs.1.question"), answer: t("informes_individuales.informe_patente.faqs.1.answer")},
                {id: "2", question: t("informes_individuales.informe_patente.faqs.2.question"), answer: t("informes_individuales.informe_patente.faqs.2.answer")},
                {id: "3", question: t("informes_individuales.informe_patente.faqs.3.question"), answer: t("informes_individuales.informe_patente.faqs.3.answer")},
                {id: "4", question: t("informes_individuales.informe_patente.faqs.4.question"), answer: t("informes_individuales.informe_patente.faqs.4.answer")}
            ]);
                break;
            case "/informe-de-multas": 
                setIsInformeNominal(false)
                setBgColor("red"); 
                setBgColorCode('#C36567'); 
                setTitle(t('informes_individuales.informe_multas.title')||""); 
                setSubtitle(t('informes_individuales.informe_multas.subtitle')||""); 
                //TODO: change the mockup here
                setImg("/dominio-mockup.png"); 
                setDescription(t('informes_individuales.informe_multas.description')||"");
                setFaqs([{id: "1", question: t("informes_individuales.informe_multas.faqs.1.question"), answer: t("informes_individuales.informe_multas.faqs.1.answer")},
                {id: "2", question: t("informes_individuales.informe_multas.faqs.2.question"), answer: t("informes_individuales.informe_multas.faqs.2.answer")},
                {id: "3", question: t("informes_individuales.informe_multas.faqs.3.question"), answer: t("informes_individuales.informe_multas.faqs.3.answer")},
                {id: "4", question: t("informes_individuales.informe_multas.faqs.4.question"), answer: t("informes_individuales.informe_multas.faqs.4.answer")}
            ]);
                break;
            case "/informe-nominal": 
                setIsInformeNominal(true)
                setBgColor("yellow"); 
                setBgColorCode('#BBC667'); 
                setTitle(t('informes_individuales.informe_nominal.title')||""); 
                setSubtitle(t('informes_individuales.informe_nominal.subtitle')||""); 
                //TODO: change the mockup here
                setImg("/dominio-mockup.png"); 
                setDescription(t('informes_individuales.informe_nominal.description')||"");
                setFaqs([{id: "1", question: t("informes_individuales.informe_nominal.faqs.1.question"), answer: t("informes_individuales.informe_nominal.faqs.1.answer")},
                {id: "2", question: t("informes_individuales.informe_nominal.faqs.2.question"), answer: t("informes_individuales.informe_nominal.faqs.2.answer")},
                {id: "3", question: t("informes_individuales.informe_nominal.faqs.3.question"), answer: t("informes_individuales.informe_nominal.faqs.3.answer")},
                {id: "4", question: t("informes_individuales.informe_nominal.faqs.4.question"), answer: t("informes_individuales.informe_nominal.faqs.4.answer")}
            ]);
                break;
            default: 
                break;
        }
    }, [location.pathname])


  return (
    <>
        <div style={{backgroundColor: "#EFFAFF"}}>
            <Container fluid style={
              (isDesktopOrLaptop || isBigScreen) 
              ? 
              {zIndex: 3, backgroundImage: `url(/bg-top-${bgColor}.png)`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', minHeight: "1562px", backgroundPosition: "center"} 
              : 
              (isMobile) ?
              {backgroundImage: `url(/bg-mobile-${bgColor}.svg)`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: "top"}
              :
              (isTablet) ?
              {backgroundImage: `url(/bg-tablet-${bgColor}.svg)`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: "center", minHeight: "2045px"}
              :
              {}
            }>
                <NavBar />
                
                <Container id='header-individual' style={{}}>
                    <Row style={{paddingTop: `${(isBigScreen || isBigScreen) ? "150px" : "30px" }`}}>
                        <Col lg={'5'}>
                            <Container style={{fontFamily: "Montserrat", color: "#ffffff"}}>
                                <h1 style={{fontSize: "55px", fontWeight: "900", paddingBottom: "20px"}}>{title}</h1>
                                <h3 style={{fontSize: "18px", fontWeight: "400", paddingBottom: "20px"}}>{subtitle}</h3>
                                <Button id='button-primary' style={{backgroundColor: `${bgColorCode}`}} href='#formulario'>{t('landing_page.header.button')}</Button>
                            </Container>
                        </Col>
                        <Col lg={'7'}>
                            <img src={img} alt='mockup-informes-digital' width={(isBigScreen || isDesktopOrLaptop) ? '160%' : '150%'} height={'auto'} style={(isBigScreen || isDesktopOrLaptop) ? {marginTop: "-120px", marginLeft: "-120px"} : {marginLeft: "-90px"}}/>
                        </Col>
                    </Row>
                </Container>
                <Container id='body-individual' style={{ zIndex: 4 ,fontFamily: "Montserrat", paddingTop: `${(isBigScreen || isDesktopOrLaptop) ? "0px" : "50px"}`, paddingBottom: `${(isBigScreen || isDesktopOrLaptop) ? "250px" : "50px"}`}}>
                    <Row>
                        <Col>
                            <Container style={{color: "#5C5E6E", paddingTop: "20px"}}>
                                <Row>
                                    <Col>
                                    <h3 style={{fontWeight: "800", fontSize: `${(isBigScreen || isDesktopOrLaptop) ? "2.5rem" : "1.8rem"}`, fontStyle: "italic", color: "#ffffff", paddingBottom: "30px", textAlign: `${(isBigScreen || isDesktopOrLaptop) ? "left" : "center"}`}}>{"¿Que es el " + title + "?"}</h3>
                                        <Card>
                                            <Card.Body style={{display: "flex", alignContent: "center", flexWrap: "wrap"}}>
                                                <Card.Subtitle style={{fontWeight: "400", lineHeight: "2rem"}}>{description}</Card.Subtitle>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    <Col hidden={isTabletOrMobile} style={{color: `${bgColorCode}`, marginLeft: `${(isBigScreen || isDesktopOrLaptop) ? "150px": "0px"}`, paddingTop: `${(isBigScreen || isDesktopOrLaptop) ? "0px": "25px"}` , textAlign: `${(isBigScreen || isDesktopOrLaptop) ? "left" : "center"}`}}>
                                        <h4 style={{fontSize: `${(isBigScreen || isDesktopOrLaptop) ? "48px" : "24px"}` , fontWeight: "600", fontStyle: "italic", lineHeight: `${(isBigScreen || isDesktopOrLaptop) ? "5rem" : "2.2rem"}`}}>{t('landing_page.body.extra_message')}<p style={{fontWeight: "900"}}>{t('landing_page.body.extra_message_bold')}</p></h4>
                                        <p style={{fontSize: "20px", fontWeight: "bold"}}>{t('landing_page.body.extra_message_subtitle')}</p>
                                    </Col>
                                </Row>
                        <Col hidden={isBigScreen || isDesktopOrLaptop} style={{color: `${bgColorCode}`, marginLeft: `${(isBigScreen || isDesktopOrLaptop) ? "150px": "0px"}`, paddingTop: `${(isBigScreen || isDesktopOrLaptop) ? "0px": "35px"}` , textAlign: `${(isBigScreen || isDesktopOrLaptop) ? "left" : "center"}`}}>
                            <h4 style={{fontSize: `${(isBigScreen || isDesktopOrLaptop) ? "48px" : "24px"}` , fontWeight: "600", fontStyle: "italic", lineHeight: `${(isBigScreen || isDesktopOrLaptop) ? "5rem" : "2.2rem"}`}}>{t('landing_page.body.extra_message')}<p style={{fontWeight: "900"}}>{t('landing_page.body.extra_message_bold')}</p></h4>
                            <p style={{fontSize: "20px", fontWeight: "bold"}}>{t('landing_page.body.extra_message_subtitle')}</p>
                        </Col>
                            </Container>
                        </Col>
                    </Row>
                </Container>
            </Container>
        
        <Container id='faqs' fluid style={{fontFamily: "Montserrat", backgroundColor: "#EFFAFF"}}>
            <Row>
               <Col className='d-flex flex-column align-items-center'>
                    <h3 style={{ fontWeight: "800", maxWidth: "60%", fontSize: `${(isBigScreen || isDesktopOrLaptop) ? "48px" : "1.8rem"}`, color: "#23354E", paddingBottom: "30px", textAlign: "center"}}>{t("informes_individuales.faq_title")}</h3>
                        <Container>
                            <Accordion className='d-flex flex-column' style={{fontWeight: "400", fontSize: "24px", color: "#E3E3E3", marginBottom: "50px", border: "none", gap: "40px", paddingTop: "50px"}}>
                                    {
                                        faqs.map((faq: any, key: any) => 
                                            <Card style={{ border: "none" }}>
                                                <CardHeader style={{backgroundColor: "#23354E", borderRadius: "6px"}}>
                                                    <ContextAwareToggle eventKey={key}>
                                                        {faq.question}
                                                    </ContextAwareToggle>
                                                </CardHeader>
                                                <AccordionCollapse eventKey={key} style={{backgroundColor: "#EFFAFF", color: "#23354E", textAlign: "center"}}>
                                                    <Card.Body>
                                                        {faq.answer}
                                                    </Card.Body>
                                                </AccordionCollapse>
                                            </Card>
                                        )
                                    }
                        </Accordion>
                    </Container>
                </Col>
            </Row>
        </Container>
        <Container id='contacto' fluid style={
        (isDesktopOrLaptop || isBigScreen) 
        ? 
        {zIndex: 3, backgroundImage: `url(/bg-bottom-${bgColor}.svg)`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: "top"} 
        : 
        (isMobile) ?
        {backgroundImage: `url(/bg-bottom-${bgColor}.svg)`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: "top"}
        :
        (isTablet) ?
        {backgroundImage: `url(/bg-bottom-${bgColor}.svg)`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: "center", minHeight: "2045px"}
        :
        {}
        }>
            <Container id='formulario' style={{paddingTop: "300px", paddingBottom: "50px"}}>
                <Row>
                    <Col>
                        <h3 style={{ fontWeight: "800", fontSize: `${(isBigScreen || isDesktopOrLaptop) ? "48px" : "1.8rem"}`, color: "white", paddingBottom: "30px", textAlign: "center"}}>{t("informes_individuales.form_title") + title.toLowerCase() }</h3>
                        <FormularioInforme isInformeNominal={isInformeNominal} color={bgColorCode} />
                    </Col>
                </Row>
            </Container>
        </Container>
        </div>
        <Footer />
    </>
  )
}

export default InformeIndividual