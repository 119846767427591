import React from 'react'
import { Container } from 'react-bootstrap'
import Body from './sections/Body'
import Features from './sections/Features'
import Footer from './sections/Footer'
import Header from './sections/Header'
import NavBar from './sections/NavBar'
import Testimonials from './sections/Testimonials'
import { useMediaQuery } from 'react-responsive'
import Cards from './sections/Cards'
import Data from './sections/Data'

type Props = {}

const LandingPage = (props: Props) => {

  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1224px)'
  })
  const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
  const isTablet = useMediaQuery({ query: '(max-width: 1224px)' })
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
  const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })
  const isMobile = useMediaQuery({ query: '(max-width: 500px)' })


  return (
    <>
        <div style={{backgroundColor: "#BBD3F2"}}>
            <Container fluid>
                <NavBar />
                <Header />
                <Body />
            </Container>
                <Features />
                <Cards />
                <Data />
                <Testimonials />
        </div>
        <Footer />
    </>
  )
}

export default LandingPage