import { t } from 'i18next'
import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { useMediaQuery } from 'react-responsive'

type Props = {}

const Features = (props: Props) => {

    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
      })
      const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
      const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
      const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
      const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })
      const isMobile = useMediaQuery({ query: '(max-width: 500px)' })

  return (
    <Container fluid style={{backgroundColor: "#135EF2", paddingTop: (isMobile) ? "60px" : "100px"}}>
        <h3 style={{textAlign: "center", fontWeight: "800", color: "white", paddingBottom: (isMobile) ? "60px" : "100px"}}>{t('landing_page.features.title')}</h3>
        <Container style={{gap: "75px", zIndex: 5 ,fontFamily: "Montserrat", paddingTop: "100px", paddingBottom: "100px", display: "flex", flexDirection: "column", alignItems: "center", backgroundColor: "#BBD3F2", borderRadius: "60px"}}>
            <Row style={{width: "80%", display: "flex", alignItems: "center", flexDirection: (isMobile) ? "column" : "row", gap: (isMobile) ? "25px": "0px"}}>
                <Col style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <img alt='rapidez' src='./rapidez.svg' style={{maxHeight: "200px", width: "auto"}}></img>
                </Col>
                <Col >
                    <h3 style={{textAlign: (isMobile) ? "center" : "left", fontWeight: "600", color: "#135EF2"}}>{t("landing_page.features.1.top_title")}</h3>
                    <p style={{textAlign: (isMobile) ? "center" : "left", fontWeight: "400", color: "#135EF2"}}>{t("landing_page.features.1.title")}</p>
                </Col>
            </Row>
            <Row style={{width: "80%", display: "flex", alignItems: "center", flexDirection: (isMobile) ? "column-reverse" : "row", gap: (isMobile) ? "25px": "0px"}}>
                <Col lg="6">
                    <h3 style={{textAlign: (isMobile) ? "center" : "right", fontWeight: "600", color: "#135EF2"}}>{t("landing_page.features.2.top_title")}</h3>
                    <p style={{textAlign: (isMobile) ? "center" : "right", fontWeight: "400", color: "#135EF2"}}>{t("landing_page.features.2.title")}</p>
                </Col>
                <Col style={{display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <img alt='atencion' src='./atencion.svg' style={{width: "fit-content", maxHeight: "200px"}}></img>
                </Col>
            </Row>
            <Row style={{width: "80%", display: "flex", alignItems: "center", flexDirection: (isMobile) ? "column" : "row", gap: (isMobile) ? "25px": "0px"}}>
                <Col style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <img alt='calidad' src='./calidad.svg' style={{maxHeight: "200px", width: "auto"}}></img>
                </Col>
                <Col >
                    <h3 style={{textAlign: (isMobile) ? "center" : "left", fontWeight: "600", color: "#135EF2"}}>{t("landing_page.features.3.top_title")}</h3>
                    <p style={{textAlign: (isMobile) ? "center" : "left", fontWeight: "400", color: "#135EF2"}}>{t("landing_page.features.3.title")}</p>
                </Col>
            </Row>
            
        </Container>
    </Container>
  )
}

export default Features