import { t } from 'i18next'
import React from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap'
import { useMediaQuery } from 'react-responsive'

type Props = {}

const Body = (props: Props) => {

    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
      })
    const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
    const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })
    const isMobile = useMediaQuery({ query: '(max-width: 550px)' })

  return (
    <>
        <Container fluid style={{ zIndex: 4 ,fontFamily: "Montserrat", paddingTop: `${(isBigScreen || isDesktopOrLaptop) ? "100px" : "50px"}`, paddingBottom: `${(isBigScreen || isDesktopOrLaptop) ? "170px" : "50px"}`}}>
            <Row style={{ gap: (isMobile) ? "50px" : "0px"}}>
                <h2 style={{textAlign: "center", fontWeight: "800", color: "#135EF2", paddingBottom: (isMobile) ? "20px" : "100px"}}>{t("landing_page.body.title")}</h2>
                <Col>
                    <Card style={{display: "flex", flexDirection: "column", alignItems: "center", backgroundColor: "transparent", border: "none"}}>
                        <Card.Img src='./paso-1.svg' alt='paso 1' style={{height: "220px", width: (isMobile) ? "65vw" : "auto"}} />
                        <Card.Text style={{margin: "30px", backgroundColor: "#135EF2", borderRadius: "100px", width: "2rem", height: "2rem" ,  fontSize: "1rem", display: "flex", justifyContent: "center", alignItems: "center", fontWeight: "700", color: "white"}}>
                            {"1"}
                        </Card.Text>
                        <Card.Title style={{color: "#3E588C"}}>
                            {t("landing_page.body.cards.1.title")}
                        </Card.Title>
                    </Card>
                </Col>
                <Col>
                    <Card style={{display: "flex", flexDirection: "column", alignItems: "center", backgroundColor: "transparent", border: "none"}}>
                        <Card.Img src='./paso-2.svg' alt='paso 2' style={{height: "220px", width: (isMobile) ? "65vw" : "auto"}} />
                        <Card.Text style={{margin: "30px", backgroundColor: "#135EF2", borderRadius: "100px", width: "2rem", height: "2rem" ,  fontSize: "1rem", display: "flex", justifyContent: "center", alignItems: "center", fontWeight: "700", color: "white"}}>
                            {"2"}
                        </Card.Text>
                        <Card.Title style={{color: "#3E588C"}}>
                            {t("landing_page.body.cards.2.title")}
                        </Card.Title>
                    </Card>
                
                </Col>
                <Col>
                    <Card style={{display: "flex", flexDirection: "column", alignItems: "center", backgroundColor: "transparent", border: "none"}}>
                        <Card.Img src='./paso-3.svg' alt='paso 3' style={{height: "220px", width: (isMobile) ? "65vw" : "auto"}} />
                        <Card.Text style={{margin: "30px", backgroundColor: "#135EF2", borderRadius: "100px", width: "2rem", height: "2rem" ,  fontSize: "1rem", display: "flex", justifyContent: "center", alignItems: "center", fontWeight: "700", color: "white"}}>
                            {"3"}
                        </Card.Text>
                        <Card.Title style={{color: "#3E588C"}}>
                            {t("landing_page.body.cards.3.title")}
                        </Card.Title>
                    </Card>
                
                </Col>

            </Row>
        </Container>
    </>
  )
}

export default Body