import axios from 'axios'
import { t } from 'i18next'
import React, { useState } from 'react'
import { Alert, Button, Form, FormControl, FormGroup, FormLabel } from 'react-bootstrap'
import { redirect } from 'react-router-dom'

type Props = {}

const FormularioContacto = (props: Props) => {

    const baseUrl = process.env.REACT_APP_API_URL

    const config = {
        headers:{
        'content-type': 'application/json;charset=UTF-8',
        'accept': 'application/json, text/plain, */*'
        }
    }

    const [validated, setValidated] = useState(false);
    const [email, setEmail] = useState("")
    const [name, setName] = useState("")
    const [message, setMessage] = useState("")
    const [showAlert, setShowAlert] = useState(false)
    const [showDangerAlert, setShowDangerAlert] = useState(false)


    const emailInputHandler = (event: any) => {
        event.preventDefault()
        setEmail(event.target.value)
    }

    const nameInputHandler = (event: any) => {
        event.preventDefault()
        setName(event.target.value)
    }

    const messageInputHandler = (event: any) => {
        event.preventDefault()
        setMessage(event.target.value)
    }

    const data = { "name": name, "email": email, "comment": "Mensaje enviado desde pack.informe.digital: " + message, "website": "pack" }


    const handleSubmit = async(event: any) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
          event.preventDefault();
          event.stopPropagation();
        }
        else {
        event.preventDefault();
        setValidated(true);

        await axios.post(`${baseUrl}send-contact-email`, data, config).then((res) => {
            setShowAlert(true)
            setValidated(false);
            setEmail("")
            setName("")
            setMessage("")
        }).catch(e => {console.log(e); setShowDangerAlert(true)})}
    }

    const primaryColor = "#135EF2"

  return (
    <>
        <Alert className='d-flex flex-row justify-content-between align-items-center' show={showAlert} variant='success'>
            {t("success_message_sent")}
            <Button onClick={() => setShowAlert(false)} variant="outline-success">
                {'Cerrar'}
            </Button>
        </Alert>
        <Alert className='d-flex flex-row justify-content-between align-items-center' show={showDangerAlert} variant='danger'>
            {t("danger_message_not_sent")}
            <Button onClick={() => setShowDangerAlert(false)} variant="outline-danger">
                {'Cerrar'}
            </Button>
        </Alert>
        <Form 
        validated={validated} 
        onSubmit={handleSubmit}>
            <FormGroup style={{paddingBottom: "30px"}} controlId="validationCustom01">
                <FormLabel style={{color: primaryColor}}>{'Correo Electrónico'}</FormLabel>
                <FormControl defaultValue={email} value={email} style={{height: "60px"}} type='email' onChange={emailInputHandler} required></FormControl>
                <FormControl.Feedback type='invalid'>{'Por favor escribe tu correo electrónico'}</FormControl.Feedback>
            </FormGroup>
            <FormGroup style={{paddingBottom: "30px"}} controlId="validationCustom01">
                <FormLabel style={{color: primaryColor}}>{'Nombre'}</FormLabel>
                <FormControl defaultValue={name} value={name} style={{height: "60px"}} type='text' onChange={nameInputHandler} required></FormControl>
                <FormControl.Feedback type='invalid'>{'Por favor escribe tu nombre'}</FormControl.Feedback>
            </FormGroup>
            <FormGroup style={{paddingBottom: "30px"}} controlId="validationCustom01">
                <FormLabel style={{color: primaryColor}}>{'¿Cómo podemos ayudarle?'}</FormLabel>
                <FormControl defaultValue={message} value={message} style={{height: "120px"}} as='textarea' onChange={messageInputHandler} required></FormControl>
                <FormControl.Feedback type='invalid'>{'Por favor escribe tu mensaje'}</FormControl.Feedback>
            </FormGroup>
            <Button type='submit' style={{marginTop: "20px", backgroundColor: primaryColor}} id='button-primary'>{'Enviar Mensaje'}</Button>
        </Form>
    </>
  )
}

export default FormularioContacto