import { t } from 'i18next'
import React from 'react'
import { Button, Card, Col, Container, Row } from 'react-bootstrap'
import { useMediaQuery } from 'react-responsive'

type Props = {}

const Cards = (props: Props) => {

    const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
    const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })
    const isMobile = useMediaQuery({ query: '(max-width: 500px)' })


    const cardStyle: any = {display: "flex", flexDirection: "column", alignItems: "center", gap: "40px", backgroundColor: "#BBD3F2", borderRadius: "60px", cursor: "pointer"}
    const cardImageStyle: any = {padding: "30px", height: "320px", maxWidth: "80vw"}
    const cardTitleStyle: any = {fontWeight: "800", fontSize: "1.6rem", color: "#135EF2", paddingBottom: "60px"}

  return (
    <Container fluid style={{fontFamily: "Montserrat", paddingTop: "100px", paddingBottom: "100px", backgroundColor: "#135EF2"}}>
        <Col>
            <h3 style={{textAlign: "center", fontWeight: "800", color: "white", paddingBottom: (isTabletOrMobile) ? "60px" : "100px"}}>{t("landing_page.cards.title")}</h3>
        </Col>
        <Row style={{display: "flex", flexDirection: (isMobile) ? "column" : "row", gap: (isMobile) ? "30px": "0px", paddingBottom: "50px"}}>
            <Col>
                <Card onClick={() => window.location.assign('https://informes.digital/?utm_source=pack_informes_digital&utm_medium=cards&utm_campaign=pack')} style={cardStyle}>
                    <Card.Img src='./dominio.svg' style={cardImageStyle} />
                    <Card.Title style={cardTitleStyle}>
                        {t("landing_page.cards.1")}
                    </Card.Title>
                </Card>
            </Col>
            <Col>
                <Card onClick={() => window.location.assign('https://informes.digital/informe-de-multas/?utm_source=pack_informes_digital&utm_medium=cards&utm_campaign=pack')} style={cardStyle}>
                    <Card.Img src='./multas.svg' style={cardImageStyle} />
                    <Card.Title style={cardTitleStyle}>
                        {t("landing_page.cards.2")}
                    </Card.Title>
                </Card>
            </Col>
            <Col>
                <Card onClick={() => window.location.assign('https://informes.digital/radicacion-de-patente/?utm_source=pack_informes_digital&utm_medium=cards&utm_campaign=pack')} style={cardStyle}>
                    <Card.Img src='./radicacion.svg' style={cardImageStyle} />
                    <Card.Title style={cardTitleStyle}>
                        {t("landing_page.cards.4")}
                    </Card.Title>
                </Card>
            </Col>
            <Col>
                <Card onClick={() => window.location.assign('https://informes.digital/radicacion-de-patente/?utm_source=pack_informes_digital&utm_medium=cards&utm_campaign=pack')} style={cardStyle}>
                    <Card.Img src='./patentes.svg' style={cardImageStyle} />
                    <Card.Title style={cardTitleStyle}>
                        {t("landing_page.cards.3")}
                    </Card.Title>
                </Card>
            </Col>
        </Row>
        <Row>
            <Button id='button-primary' style={{maxHeight: "63px"}} onClick={() => document.location = "https://informes.digital/?utm_source=pack_informes_digital&utm_medium=cards&utm_campaign=pack"}>{t('landing_page.cards.button')}</Button>
        </Row>


        


    </Container>
  )
}

export default Cards