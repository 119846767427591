import { t } from 'i18next'
import React from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import { useMediaQuery } from 'react-responsive'
import { useNavigate } from 'react-router-dom'


type Props = {}

const Header = (props: Props) => {

    const navigate = useNavigate()

    const isDesktopOrLaptop = useMediaQuery({
      query: '(min-width: 1224px)'
    })
    const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
    const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })



  return (
    <>
        <Container style={{display: "flex"}}>
            <Row style={{paddingTop: `${(isBigScreen || isBigScreen) ? "150px" : "30px" }`, paddingBottom: "50px", display: 'flex', flexDirection: "row"}}>
                <Col style={{}}>
                    <Container style={{fontFamily: "Montserrat", color: "#135EF2"}}>
                        <h1 style={{fontSize: "48px", fontWeight: "900", paddingBottom: "5px", }}>{t('landing_page.header.title')}</h1>
                        <h2 style={{fontSize: "28px", fontWeight: "400", paddingBottom: "5px", color: "#135EF2"}}>{t('landing_page.header.subtitle')}</h2>
                        <h3 style={{fontSize: "16px", fontWeight: "400", paddingBottom: "15px", color: "#0D378C"}}>{t('landing_page.header.description')}</h3>
                        <Row style={{flexDirection: (isTabletOrMobile) ? "column" : "row", gap: "10px"}}>
                          <Col>
                            <Button id='button-primary' onClick={() => navigate('/form')}>{t('landing_page.header.button')}</Button>
                          </Col>
                          <Col>
                            <Button id='button-primary-transparent' style={{maxHeight: "63px"}} onClick={() => document.location = "https://informes.digital/?utm_source=pack_informes_digital&utm_medium=landing_btn_header&utm_campaign=pack"}>{t('landing_page.header.button_1')}</Button>
                          </Col>

                        </Row>
                    </Container>
                </Col>
                <Col lg={"7"} style={{backgroundPositionX: "right", backgroundSize: "contain" , backgroundRepeat: "no-repeat", backgroundImage: "url(./chico-header.png)"}}></Col>
            </Row>
        </Container>
    </>
  )
}

export default Header