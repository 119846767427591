import { t } from 'i18next'
import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { useMediaQuery } from 'react-responsive'

type Props = {}

const Footer = (props: Props) => {

  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1224px)'
  })
  const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
  const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })

  return (
    <>
        <Container fluid style={{display: "flex", flexDirection: "column", gap: "45px", paddingTop: "100px",paddingLeft: `${(isBigScreen || isDesktopOrLaptop) ? "180px" : "0px"}`, paddingBottom: "100px", backgroundColor: "#BBD3F2"}}>
            <img src='./logo-blue.svg' alt='logo' style={{width: `${(isBigScreen || isDesktopOrLaptop) ? "400px" : "80vw"}`, paddingLeft: `${(isBigScreen || isDesktopOrLaptop) ? "0px" : "10vw"}` }}></img>
            <Row>
              <Col style={{display: "flex", flexDirection: (isTabletOrMobile) ? "column" : "row", gap: "10px"}}>
                <a href='https://informes.digital/?utm_source=pack_informes_digital&utm_medium=footer&utm_campaign=pack'> {t('landing_page.navbar.1')}</a>
                <a href='https://informes.digital/radicacion-de-patente/?utm_source=pack_informes_digital&utm_medium=footer&utm_campaign=pack'> {t('landing_page.navbar.2')}</a>
                <a href='https://informes.digital/informe-de-multas/?utm_source=pack_informes_digital&utm_medium=footer&utm_campaign=pack'> {t('landing_page.navbar.3')}</a>
                <a href='https://informes.digital/informe-nominal/?utm_source=pack_informes_digital&utm_medium=footer&utm_campaign=pack'> {t('landing_page.navbar.4')}</a>
              </Col>
            </Row>
        </Container>
        <Container fluid style={{backgroundColor: "#135EF2", display: "flex", flexDirection: "column", height: "auto", paddingLeft: `${(isBigScreen || isDesktopOrLaptop) ? "180px" : "0px"}`, justifyContent: "center"}}>
          <span style={{fontFamily: "Montserrat", fontWeight: "400", fontSize: "13px", color: "white", paddingTop: "10px", paddingBottom: "10px", paddingLeft: `${(isBigScreen || isDesktopOrLaptop) ? "0px" : "10vw"}`, paddingRight: `${(isBigScreen || isDesktopOrLaptop) ? "0px" : "10vw"}`}}>{t("landing_page.footer.disclaimer")}</span>
        </Container>
    </>
  )
}

export default Footer